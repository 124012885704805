.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 2000px) {
    max-height: 650px;
  }
  @media (max-width: 1600px) {
    max-height: 435px;
  }
  @media (max-width:450px) {
    gap: 20px;
    max-height: 100%;
  }
}
.list {
  @media (max-width: 2000px) {
    max-height: 650px;
  }
  @media (max-width: 1600px) {
    max-height: 435px;
  }

  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgb(240, 240, 240); /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #351acb; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 1px solid #351acb; /* creates padding around scroll thumb */
  }

  @media (max-width:450px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 635px !important;
    height: 100%;
  }
}
