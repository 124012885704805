.body{
    display: flex;
    flex-direction: column;
    height: 97% !important;
    h1{
        margin-bottom: 20px;
    }
}
.editors{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.socialMedia{
    margin: 20px 0 20px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width:480px) {
        max-width: 100%;
        width: 100%;
        & > div{
        width: 100%;
        }
        input{
            max-width: 100%;
            width: 100%;
        }
    }
}
.contentItem{
    width: 100%;
}
.contentItemBtn{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 4px;
    width: 100%;
    font-size: 20px;
    font-family: inherit;
    background: rgb(227, 227, 227);
    padding: 14px 12px;
    border-radius: 3px;
    transition: all 0.3s ease;
    color: black;
    &:hover{
        background: rgb(198, 198, 198);
    }
    &:active{
        background: rgb(172, 172, 172);
    }
}
.saveBtn{
    margin-top: auto;
}
.editorBtn{
    margin-top: 50px;

    @media (max-width:780px) {
        margin-top: 110px;
    }
}