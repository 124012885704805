.btns {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 780px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.btnsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.body {
  @media (max-width: 780px) {
    margin-top: 15px;
    min-width: 100%;
    overflow: auto;
    border: 2px solid #5e1acb;
    border-radius: 8px;
  }
}
.head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.2fr 0.8fr 0.8fr 1fr 0.8fr 1fr 1fr;
  margin-top: 15px;
  border: 1px solid #5e1acb;
  border-radius: 8px;
  padding: 12px 7px;
  @media (max-width: 780px) {
    padding: 12px 0px 22px 62px;
    min-width: 100%;
    max-width: 100%;
    border: none;
  }
}
.headItem {
  max-width: 110px;
  display: flex;
  font-size: 13px;
  color: rgb(79, 79, 79);
  @media (max-width: 780px) {
    min-width: 110px;
  }
}
.addBtn {
  margin-left: auto;
}
.list {
  max-height: 460px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgb(240, 240, 240); /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #351acb; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 1px solid #351acb; /* creates padding around scroll thumb */
  }
  @media (max-width: 1600px) {
    max-height: 340px;
  }
}
