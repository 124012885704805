.body {
  width: 100%;
  margin-left: auto;
  margin: 0px 10px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  max-height: 100px;
  border-radius: 24px;
  background: linear-gradient(
    0deg,
    #5e1acb 80.26%,
    rgba(87, 5, 219, 0) 173.39%,
    #6124bc 143.39%
  );
  box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
  color: white;
  padding: 30px;

  @media (max-width:780px) {
    margin: 0;
    margin-bottom: 10px;
  }
}
.user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.userImg {
  font-size: 28px;
  background: rgba(85, 45, 135, 0.772);
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 70px;
  height: 70px;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}
.userName {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}
.userStatus {
  font-size: 14px;
}
.ball {
  color: white;
  font-size: 28px;
}
.btns {
  display: flex;
  align-items: center;
  gap: 35px;

  & button {
    transition: all 0.3s ease;
  }
  & button:hover {
    color: #f554f5;
  }

  @media (max-width: 780px) {
    gap: 20px;
  }
}
.exit {
  color: white;
  font-size: 24px;
  @media (max-width:780px) {
    display: none;
  }
}
.langs {
  padding: 5px;
  border-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    color: black;
    font-family: inherit;
    font-size: 16px;
    padding: 4px;
  }
  button:hover {
    color: rgba(76, 76, 76, 0.671);
  }
  span {
    color: black;
  }
  @media (max-width: 780px) {
    display: none;
  }
}
.lang.current {
  color: rgb(255, 255, 255);
  background: rgba(48, 45, 135, 0.772);

  border-radius: 4px;
  &:hover {
    color: rgb(255, 255, 255);
  }
}
.ballWrapper {
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}
.applicationsValue {
  position: absolute;
  bottom: 1px;
  left: -6px;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.mobileBtn {
  display: none;
  @media (max-width: 780px) {
    display: block;
    font-size: 28px;
    color: white;
  }
}
