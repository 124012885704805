.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(14, 14, 14, 0.25);
  visibility: hidden;
  z-index: 100000;
  opacity: 0;

  transition: all 0.3s ease;
}
.body {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
  height: 100%;
  width: 260px;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  transform: translateX(260px);

  transition: all 0.3s ease;
}
.modalBodyVisible {
  transform: translateX(0px);
}
.modalVisible {
  visibility: visible;
  opacity: 1;
}
.label {
  font-size: 20px;
}
.logo {
  a {
    max-width: fit-content;
    font-family: "Oxanium", sans-serif;
    font-size: 32px;
    color: #6124bc;
  }
}
.links {
  display: flex;
  flex-direction: column;
  gap: 22px;
  a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: black;
    font-size: 18px;
  }
}
.head {
  margin-bottom: 30px;
}
.langs {
  margin-top: 20px;
  padding: 5px;
  border-radius: 10px;
  background: white;
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    color: black;
    font-family: inherit;
    font-size: 16px;
    padding: 4px;
  }
  button:hover {
    color: rgba(76, 76, 76, 0.671);
  }
  span {
    color: black;
  }
}
.lang.current {
  color: rgb(255, 255, 255);
  background: rgba(48, 45, 135, 0.772);

  border-radius: 4px;
  &:hover {
    color: rgb(255, 255, 255);
  }
}
.actions {
  margin-top: 24px;
}
.logout {
  font-size: 28px;
  color: black;
}
