.body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  background: linear-gradient(
    0deg,
    #5e1acb 80.26%,
    rgba(87, 5, 219, 0) 173.39%,
    #6124bc 143.39%
  );

  padding: 16px 20px;
  border-radius: 20px;
  width: 360px;
}
.title {
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  color: white;
}
