* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
a {
  text-decoration: none;
  color: indianred;
}
button {
  cursor: pointer;
  border: none;
  background: transparent;
}
.title-main {
  font-family: "Oxanium", sans-serif;
  font-size: 20px;
  color: #000000;
}
.wrapper {
  width: 99.5%;
  height: 82.8%;
  margin: 15px;
  border-radius: 14px;
  box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(146, 95, 226, 0.3);
  padding: 20px;
}
.hide-scroll{
  height: 100vh;
  overflow: hidden;
}

@media (max-width:780px) {
  .wrapper {
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 14px;
    box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(146, 95, 226, 0.3);
    padding: 20px;
  }
}