.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.301);
    z-index: 10;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden !important;
}
.wrapper{
    max-width: 300px;
    height: auto;
    @media (max-width:390px) {
        max-width: 150px;
    }
}