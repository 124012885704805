.body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.2fr 0.8fr 0.8fr 1fr 0.8fr 1fr 1fr;

}
.ticketItem{
  @media (max-width:780px) {
    min-width: 110px;
  }
}
.wrapper {
  position: relative;
  border: 1px solid #5a595b30;
  border-radius: 8px;
  padding: 40px 0px 40px 12px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.035);
  @media (max-width:780px) {
    min-width: fit-content;
    padding: 40px 0px 40px 62px;
  }
}
.btns {
  top: 2px;
  right: 3px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 3px;
  @media (max-width:780px) {
    left: 0;
  }
}
