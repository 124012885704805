.body {
  width: 100%;
}
.btns {
  max-width: fit-content;
  display: flex;
  gap: 6px;
  margin-left: auto;

  @media (max-width:780px) {
    margin-top: 30px;
  }
}
.inputs {
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  div {
    max-width: 31%;
    width: 100%;
    @media (max-width:780px) {
      min-width: 100%;
    }
  }
  input {
    width: 100%;
  }
}
.currencyInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
  div {
    max-width: 100%;
  }
  button {
    width: 100%;
  }
}
.currencyLabel {
  opacity: 0.9;
  margin-left: 4px;
}
.coordinates{
  width: 100%;
  display: flex;
  gap: 12px;
  @media (max-width:780px) {
    flex-direction: column;
  }
  div {
    max-width: 31%;
    width: 100%;
    @media (max-width:780px) {
      min-width: 100%;
    }
  }
  input {
    width: 100%;
  }
}