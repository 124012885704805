.body{
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 20px;
    padding: 8px;
    border: 1px solid black;
    border-radius: 30px;
    input{
        font-family: inherit;
        font-size: 15px;
        border: none;
        outline: none;
    }
}