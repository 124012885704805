.body{
    max-width: fit-content;
    display: flex;
    border-radius: 10px;
    border: 1px solid blue;
    overflow: hidden;
    button{
        font-family: inherit;
        font-size: 15px;
        padding: 10px;
        transition: all 0.3s ease;
        &:hover{
            background: rgba(21, 21, 224, 0.128);
        }

        &:active{
            background: rgba(21, 21, 224, 0.329);
        }
    }

}
.selected{
    background: rgb(21, 21, 224);
    color: white;

    &:hover{
        background: rgb(21, 21, 224) !important;
    }
}