.btns {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

}
.head {
  display: flex;
  align-items: center;
  gap: 200px;
  margin-top: 15px;
  border: 1px solid #5e1acb;
  border-radius: 8px;
  padding: 12px 7px;
  @media (max-width:780px) {
    gap: 40px;
  }
}

.headItem {
  max-width: 110px;
  display: flex;
  font-size: 13px;
  color: rgb(79, 79, 79);
  
}
.addBtn {
  margin-left: auto;
}
.list {
  max-height: 460px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: rgb(240, 240, 240); /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #351acb; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 1px solid #351acb; /* creates padding around scroll thumb */
  }
  @media (max-width: 1600px) {
    max-height: 340px;
  }

}
.item {
  display: flex;
  align-items: center;
  gap: 150px;
  @media (max-width:780px) {
    gap: 40px;
  }
}
.itemValue {
  width: 86px;
  div {
    font-size: 14px;
  }
  @media (max-width:780px) {
    width: 185px;
  }
}
.itemWrapper {
  position: relative;
  border: 1px solid #5a595b30;
  border-radius: 8px;
  padding: 40px 0px 40px 12px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.035);
  @media (max-width:780px) {
    min-width: fit-content;
  }
}
.btns {
  margin: 0;
  top: 2px;
  right: 3px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.active {
  color: green;
}
.notActive {
  color: red;
}
.search {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.options {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin: 15px 0;
  @media (max-width:450px) {
    flex-wrap: wrap;
  }
}
.label {
  font-size: 15px;
  color: gray;
}
