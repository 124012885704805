.body{
    max-width: 98vw;
    margin: 20px auto;
    display: flex;
    @media (max-width:780px) {
        padding: 5px;
        margin: 0 auto;
        height: 100%;
    }
}
.page{
    width: 100%;
}