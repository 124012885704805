.wrapper{
  gap: 50px;
  width: 99.5%;
  height: 82.8%;
  margin: 15px;
  border-radius: 14px;
  box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(146, 95, 226, 0.3);
  padding: 20px;
}
.body {
  display: flex;
  gap: 80px;
  width: 100%;
  margin-top: 20px;
}
.statistic {
  width: 100%;
  max-width: 500px;
}
.title {
  margin-bottom: 25px;
  margin-left: 40px;
}
