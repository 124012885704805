.fakeInput {
  position: relative;
  input {
    max-width: fit-content;
    position: absolute;
    top: 0;
    opacity: 0;
    right: -27px;
    cursor: pointer;
  }
}
.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fileDescription {
  max-width: 185px;
  font-size: 14px;
  line-height: 16px;

  color: #738094;
}
.submit {
  margin-top: 16px;
}
.fake {
  cursor: pointer;
  font-family: inherit;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: rgb(44, 57, 171);
  transition: all 0.3s ease;

  &:hover{
    color: rgb(12, 21, 108);
  }
}
.label{
  opacity: 0.9;
  margin-left: 4px;
  margin-bottom: 8px;
}
.inputWrapper{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.imgView{
  width: 100px;
  height: 100px;
  border: 1.5px solid #6352b8;
  border-radius: 6px;
  background: rgba(220, 220, 220, 0.555);
  @media (max-width:450px) {
    width: 80px;
    height: 80px;
  }
}
.img{
  width: 100%;
  height: 100%;
  border-radius: 6px;
}