.body {
  max-width: 320px;
  width: 100%;
}
.title {
  font-size: 20px;
  font-family: 500;
  color: rgba(0, 0, 0, 0.8);
}
.list {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.admin {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border: 1px solid rgba(146, 95, 226, 0.3);
  border-radius: 12px;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.img {
  font-size: 28px;
  img{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.status {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 400;
}
.delete {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  margin-left: auto;
  font-size: 22px;
  border: 1px solid rgba(146, 95, 226, 0.3);

  transition: all 0.3s ease;

  &:hover {
    background: rgba(146, 95, 226, 0.3);
  }
  &:active {
    background: rgba(146, 95, 226, 0.5);
  }
}
.createAdminBody{
  margin: 18px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width:450px) {
    gap: 8px;
  }
}