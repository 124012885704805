.body{
    margin: 20px 0;
}
.title{
    font-size: 20px;
    font-weight: 500;
}
.items{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.item{
    display: flex;
    flex-direction: column;
    gap: 12px;

    max-width: 300px;
    width: 100%;
    padding: 12px;
    border-radius: 15px;
    border: 1px solid blue;
    background: rgba(222, 222, 222, 0.212);
}
.addBtnWrapper{
    max-width: 300px;
    width: 100%;
    height: 100%;
}
.addBtn{
    max-width: 300px;
    width: 100%;
    height: 655px;
    padding: 12px;
    border-radius: 15px;
    border: 1px solid blue;
    background: white;
    color: blue;
    font-size: 32px;
    transition: all 0.3s ease;

    &:hover{
        background: rgba(222, 222, 222, 0.411);
    }
    &:active{
        background: rgba(179, 179, 179, 0.411);
    }
}