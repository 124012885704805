.body{
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.title{
    font-size: 20px;
    font-weight: 500;
}
.editor{
    height: 400px;
}