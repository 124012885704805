.body {
  width: 143px;
  position: relative;
}
.selectBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border: 1.5px solid rgb(44, 57, 171);
  border-radius: 5px;
  color: rgb(70, 70, 70);
  span {
    font-size: 15px;
  }
  svg {
    transition: transform 0.1s ease;
    font-size: 14px;
  }
  transition: all 0.3s ease;

  &:hover {
    background: rgb(44, 57, 171);
    color: white;
  }
}
.open {
  svg {
    transform: rotate(540deg);
  }
}
.list {
  position: absolute;
  top: 27px;
  display: none;
  flex-direction: column;
  gap: 4px;
  border: 1px solid;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  button {
    width: 143px;
    padding: 8px 4px;
    transition: all 0.3s ease;
    &:hover {
      background: rgba(0, 0, 0, 0.089);
    }
    &:active {
      background: rgba(0, 0, 0, 0.15);
    }
  }
  z-index: 1;
}
.visible {
  display: flex;
}
.selected {
  background: rgb(44, 57, 171);
  color: white;
}
.list button.selected {
  &:hover {
    background: rgb(44, 57, 171);
    color: white;
  }
}
