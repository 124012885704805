.body {
  position: relative;
  border-radius: 8px;
  padding: 20px 18px 20px 18px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.035);
  border: 1px solid rgba(90, 89, 91, 0.1882352941);
  background: rgba(241, 241, 8, 0.046);
}
.row {
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1.3fr 1fr 1fr 1fr;
  row-gap: 15px;

  @media (max-width: 1460px) {
    gap: 10px;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
}
.infoItem {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.key {
  color: rgb(87, 87, 87);
}
.values {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.value {
  font-size: 16px;
  font-weight: 500;
}
.passengers {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.passengersList {
  display: flex;
  gap: 10px;
}
.passenger {
  display: flex;
  padding: 6px;
  border-radius: 6px;
  background: white;
  border: 1px solid rgb(87, 87, 251);
  gap: 10px;
}
.passengerItem {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.passengerKey {
  font-size: 14px;
  color: rgb(63, 62, 62);
}
.actions {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
.confirmed {
  background: rgba(7, 243, 7, 0.048);
}
.actionBtns {
  position: absolute;
  bottom: 20px;
  right: 20px;
  a {
    font-family: "Oxanium", sans-serif;
    border-radius: 10.238px;
    background: white;
    padding: 4px 4px;
    font-size: 22px;
    border-radius: 8px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(44, 57, 171);

    transition: all 0.3s ease;

    &:hover {
      background: rgb(31, 38, 100);
      color: white;
    }
    &:active {
      background: rgb(19, 24, 70);
    }
    border: 1px solid rgba(2, 2, 132, 0.308);
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.094);

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    &:disabled:hover {
      background: white;
      color: rgb(44, 57, 171);
    }
  }
}
.ticketNumber {
  margin-bottom: 12px;
  font-weight: 500;
}
