.body {
  max-width: 320px;
  height: 94vh;
  border-radius: 15.633px;
  background: linear-gradient(
    0deg,
    #5e1acb 80.26%,
    rgba(87, 5, 219, 0) 173.39%,
    #6124bc 143.39%
  );
  padding: 30px 25px;

  @media (max-width:780px) {
    display: none;
  }
}
.head {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.logo a {
  max-width: fit-content;
  font-family: "Oxanium", sans-serif;
  font-size: 32px;
  color: white;
}

.label {
  color: rgba(255, 255, 255, 0.75);
}
.links {
  margin: 50px 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.links a {
  max-width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-size: 18px;
  padding: 8px;
  transition: all 0.3s ease;
}

.links a:hover {
  color: #c5b1e6;
}
.links a:active{
  color: #9284a7;
}
.links .current{
  box-shadow: 0px 0px 0px 1.5px #d0d0d0;
  border-radius: 8px;
}