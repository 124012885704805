.btn {
  font-family: "Oxanium", sans-serif;
  border-radius: 10.238px;
  background: white;
  padding: 4px 4px;
  font-size: 22px;
  border-radius: 8px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(44, 57, 171);

  transition: all 0.3s ease;

  &:hover {
    background: rgb(31, 38, 100);
    color: white;
  }
  &:active {
    background: rgb(19, 24, 70);
  }
  border: 1px solid rgba(2, 2, 132, 0.308);
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.094);

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &:disabled:hover {
    background: white;
  color: rgb(44, 57, 171);
  }
}
.red {
  background: rgb(218, 0, 0);
  border: 1px solid rgb(218, 0, 0);
  &:hover {
    background: rgb(155, 0, 0);
    border: 1px solid rgb(155, 0, 0);
  }
  &:active {
    background: rgb(109, 0, 0);
    border: 1px solid rgb(100, 0, 0);
  }
}
