.body {
  font-family: "Oxanium", sans-serif;

  height: fit-content;
  padding: 16px 8px;
  font-size: 16px;
  border-radius: 8px;

  border: 1.5px solid #6352b8;
  outline: none;
  background: rgb(238, 238, 238);
  @media (max-height:620px) {
    padding: 12px 8px;
  }
}
.input{
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.label{
  opacity: 0.9;
  margin-left: 4px;
}